import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Section from '@nib-components/section';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { Stack } from '@nib/layout';
import Layout from '../components/Layout';
import closureImg from '../img/myhealthhq/myhealthhq-closure.jpg';

import metrics from '../metrics';

const Image = styled.img`
  margin: auto;
  display: block;
`;

const metaDescription =
  'Looking for health insurance cover in New Zealand? Find out why over one million Kiwis & Aussies trust nib for their health insurance.';

const MyHealthHqClosurePage = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title> myhealthHQ closure | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <Section>
      <Stack space={{ xs: 2, md: 4 }}>
        <Image src={closureImg} />
        <Heading align="center" size={3}>
          The myhealthHQ portal you are trying to reach is no longer available.
        </Heading>
        <Copy align="center" measure={false}>
          We now have other solutions to help improve the health and wellbeing of our members.
        </Copy>
        <Copy align="center" measure={false}>
          Please reach out to your group administrator or{' '}
          <Link href="mailto:grouphealth@nib.co.nz">grouphealth@nib.co.nz</Link> with any queries.
        </Copy>
        <Copy align="center" measure={false}>
          Thank you for choosing nib to help protect your health and wellbeing.
        </Copy>
      </Stack>
    </Section>
  </Layout>
);

export default metrics({ pageName: 'myhealthhq-closure' })(MyHealthHqClosurePage);
